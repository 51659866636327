import logo from './logo.svg';
import './App.css';
import ValidateEmail from './components/ValidateEmail';

function App() {
  return (
    <div className="App-OuterCont">
      <ValidateEmail />
    </div>
  );
}

export default App;
