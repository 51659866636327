import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import './ValidateEmail.css';
import Table from '@mui/material/Table';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { ReactComponent as Validate } from '../icons/check-mark-circle-line-icon.svg';
import { ReactComponent as Invalid } from '../icons/close-round-line-icon.svg';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: '2px dashed rgb(25,118,210)',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(30),
    outline: 'none',
    marginTop: '3%',
    cursor: 'pointer',
  },
}));

const useStylesRedZone = makeStyles((theme) => ({
  dropzone: {
    border: '2px dashed red',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(30),
    outline: 'none',
    marginTop: '3%',
    cursor: 'pointer',
  },
}));

const ValidateEmail = () => {
  const classes = useStyles();
  const classesRedZone = useStylesRedZone();
  const [email, setEmail] = useState('');
  const [file, setFile] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redDropZone, setRedDropZone] = useState(false);
  const [invalidFileError, setInvalidFileError] = useState('');

  // file upload
  const onDrop = useCallback((accFiles, rejFiles) => {
    setRejectedFiles(rejFiles);
    setFile(accFiles);
    if (rejFiles.length) {
      setRedDropZone(true);
      setInvalidFileError(rejFiles[0].errors[0].code);
      setIsError(true);
      setFile([]);
    } else {
      setIsError(false);
      setRejectedFiles([]);
      setRedDropZone(false);
      setInvalidFileError('');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    maxSize: 10 * 1024 * 1024, // 10mb
  });

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const Reset = () => {
    setEmail('');
    setRejectedFiles([]);
    setFile([]);
    setRedDropZone(false);
    setIsError(false);
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (file.length === 0 && email === '') return;

      const emails = !email
        ? []
        : email.split(',').map((email) => email.trim());
      const formData = new FormData();
      formData.append('file', file[0]);

      let res;
      if (emails.length)
        res = await axios.post('http://10.101.10.108:4000/validate', {
          emails,
        });

      if (file.length)
        res = await axios.post(
          'http://10.101.10.108:4000/validateFileUpload',

          formData
        );

      if (Object.keys(res.data.result).length) {
        setRows((preValue) => [
          ...Object.entries(res.data.result).reduce((acc, [key, value]) => {
            acc.push({ email: key, isValidate: value });
            return acc;
          }, []),
          ...preValue,
        ]);
      }
      setFile([]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const exportToFile = (fileBuffer, fileName) => {
    const byteCharacters = atob(fileBuffer);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);
    const url = URL.createObjectURL(blob);
    const dummyLink = document.createElement('a');
    dummyLink.href = url;
    dummyLink.download = fileName;

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        // eslint-disable-next-line no-restricted-globals
        removeEventListener('click', clickHandler);
      }, 150);
    };

    dummyLink.addEventListener('click', clickHandler, false);

    dummyLink.click();
  };

  const downloadLatestXLSXFile = async () => {
    try {
      const controller = new AbortController();
      const signal = controller.signal;

      const result = await axios.get('http://10.101.10.108:4000/downloadXLSX', {
        signal,
      });
      exportToFile(result.data.fileBuffer, result.data.fileName);
      controller.abort();
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <>
      <h1>Validate Emails</h1>
      <div className="ValEmail-OuterCont">
        <div>
          <TextField
            className="ValEmail-TextField"
            id="outlined-multiline-static"
            label="Enter emails..."
            multiline
            rows={6}
            placeholder="Enter emails..."
            onChange={handleEmail}
            value={email}
          />
          <div
            {...getRootProps({
              className: !redDropZone
                ? classes.dropzone
                : classesRedZone.dropzone,
            })}
          >
            <input {...getInputProps()} />

            <div className={redDropZone ? 'dragDropBox red' : 'dragDropBox'}>
              <div>
                <span className="clickUploadText">
                  Choose{' '}
                  <b>
                    {' '}
                    <u>XLSX file</u>{' '}
                  </b>{' '}
                </span>{' '}
                or drag and drop
              </div>
              <div className="smallText clickUploadText">
                Maximum file size should be{' '}
                <span>
                  {' '}
                  <b>
                    {' '}
                    <u>10MB</u>
                  </b>
                </span>
              </div>

              {file.length ? (
                <div className="mt-10">
                  {' '}
                  <b> {file[0].name}</b>
                </div>
              ) : rejectedFiles.length ? (
                <b>{rejectedFiles[0].file.name}</b>
              ) : (
                <></>
              )}

              {invalidFileError && rejectedFiles.length ? (
                <div className="alert-danger-documentUpload mt-10">
                  {(rejectedFiles[0].file.size / 1048576).toFixed(3)} MB{' '}
                  {invalidFileError}{' '}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="btn-display ">
            <div className="ValEmail-Button">
              {loading ? (
                <Button
                  disabled
                  variant="contained"
                  className="ValEmail-Button"
                >
                  Loading..
                </Button>
              ) : (
                <Button
                  disabled={isError}
                  onClick={handleSubmit}
                  variant="contained"
                  className="ValEmail-Button"
                >
                  Submit
                </Button>
              )}
              <Button
                onClick={() => Reset()}
                variant="outlined"
                className="ValEmail-Button"
              >
                Reset
              </Button>
            </div>
          </div>

          <div className="btn-display ">
            <Button
              disabled={loading}
              onClick={() => downloadLatestXLSXFile()}
              variant="contained"
              className="ValEmail-Button"
            >
              Download File
            </Button>
          </div>
        </div>

        <div className="w-100">
          <TableContainer
            component={Paper}
            style={{ maxHeight: '630px', overflow: 'auto' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sr No</TableCell>
                  <TableCell>Emails</TableCell>
                  <TableCell align="right">Validated</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length ? (
                  rows.map((row, index) => (
                    <TableRow
                      key={row.email}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.email}
                      </TableCell>
                      <TableCell align="right">
                        {row.isValidate ? (
                          <Validate width={25} height={25} />
                        ) : (
                          <Invalid width={25} height={25} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell component="th" scope="row">
                      No Records
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default ValidateEmail;
